import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HttpFilter } from '../interfaces/http-filter';
import { HttpSearch } from '../interfaces/http-search';
import { Ticket } from '../interfaces/ticket';
import { AuthService } from '../services/http/auth/auth.service';
import { HttpTicketService } from '../services/http/ticket/http-ticket.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { SnackbarService } from '../services/snackbar/snackbar.service';
import { SearchComponent } from './search/search.component';

@Component({
  selector: 'homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  private httpTicketService: HttpTicketService = null;
  private httpSearchObject: HttpSearch = { limit: 5, order: {} };
  private snackbarService: SnackbarService;

  public tickets: Ticket[] = [];


  constructor(httpTicketService: HttpTicketService, snackBarService: SnackbarService, public localStorageService: LocalStorageService, private authHttpService: AuthService, private router: Router) { 
    this.httpTicketService = httpTicketService;
    this.snackbarService = snackBarService;
  }


  /**
   * Metodo ngOnInit per la richiesta GET per l'ottenimento di tutti i tickets
   */
  async ngOnInit() {

    try {
      let base64 = this.generateBase64QueryString();
      this.tickets = await this.httpTicketService.getTickets(base64);
    } catch(e) {
      this.catchError(e);
    }
    
  }


  /**
   * Metodo richiamato da @Output nel momento in cui si modifica una proprietà di ricerca nel componente search
   * @param filter Proprietà filter dell'oggetto di richiesta per il filtraggio
   */
  public async filter(filter: HttpFilter) {

    try {
      this.httpSearchObject.filter = filter;
      let base64 = this.generateBase64QueryString();
      this.tickets = await this.httpTicketService.getTickets(base64);
    } catch(e) {
      this.catchError(e);
    }
    
  }


  /**
   * Metodo richiamato da @Output nel momento in cui si modifica il valore della paginazione nel componente paginator
   * @param limit Proprietà limit da inserire nell'oggetto della richiesta
   */
  public async limit(limit?: number) {

    try {
      this.httpSearchObject.limit = limit;
      let base64 = this.generateBase64QueryString();
      this.tickets = await this.httpTicketService.getTickets(base64);
    } catch(e) {
      this.catchError(e);
    }

  }


  /**
   * Metodo per la generazione della stringa Base64 da inviare come querystring
   */
  private generateBase64QueryString() {
    let objJsonStr: string = JSON.stringify(this.httpSearchObject);
    let objJsonB64 = btoa(objJsonStr);
    return objJsonB64;
  }


  /**
   * Metodo per gestire eventuali errori verificatisi in fase di chiamate HTTP
   * @param e Eccezione effettiva
   * @param errorMessage Messaggio d'errore da visualizzare a schermo
   */
  private catchError(e, errorMessage = "Si è verificato un errore durante il download dei ticket. Per ulteriori informazioni consultare la console del browser.") {
    this.tickets = [];
    console.error(e);
    this.snackbarService.create(errorMessage, "Capito!");
  }


  /**
   * Metodo richiamato da @Output nel momento in cui si tenta di ordinare i tickets per un determinato campo
   * @param item Informazioni sulla proprietà per cui ordinare
   */
  public async sort(item: any) {
    this.httpSearchObject.order[item.name] = item.value;
    let base64 = this.generateBase64QueryString();
    this.tickets = await this.httpTicketService.getTickets(base64);
  }
  

  /**
   * Metodo richiamato da @Output nel momento in cui si vuole rimuovere un ordinamento effettuato in precedenza
   * @param item La proprietà di cui cancellare l'ordinamento
   */
  public async deleteSort(field: string) {

    if (this.httpSearchObject.order.hasOwnProperty(field))
      delete this.httpSearchObject.order[field];
      
      let base64 = this.generateBase64QueryString();
      this.tickets = await this.httpTicketService.getTickets(base64);
  }


  public async logout() {
    await this.authHttpService.logout();
    this.localStorageService.removeJwtToken();
    this.router.navigate(["/login"]);
  }

}
