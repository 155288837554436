export const environment = {
  production: true,
  url: "/backend/v2",
  freshdeskUrl: "https://safety21.freshdesk.com/a/tickets/",
  giteaUrl: "https://dev.babyloweb.eu:3000/Innosystem-Srl/BabyloWeb/issues/",
  priorities: [
    { name: "Bassa", value: 1 },
    { name: "Media", value: 2 },
    { name: "Alta", value: 3 },
    { name: "Urgente", value: 4 }
  ],
  statuses: [
    { name: "Aperto", value: 2 },
    { name: "In Sospeso", value: 3 },
    { name: "Risolto", value: 4 },
    { name: "Chiuso", value: 5 },
    { name: "Waiting On Customer", value: 6 },
    { name: "Waiting On Third Party", value: 7 },
    { name: "Work In Progress", value: 8 }
  ],
  types: [
    { name: "Nessuno", color: "white", value: "-1" },
    { name: "Incident", color: "red", value: "Incident" },
    { name: "Change Request", color: "green", value: "Change Request" },
    { name: "Change Correttiva", color: "orange", value: "Change Correttiva" },
    { name: "Change Evolutiva", color: "blue", value: "Change Evolutiva" }
  ]
};

