import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ticket } from 'src/app/interfaces/ticket';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpTicketService {

  private httpClient: HttpClient = null;


  constructor(httpClient: HttpClient, private localStorageService: LocalStorageService) {
    this.httpClient = httpClient;
  }


  /**
   * Metodo per eseguire la chiamata GET per l'ottenimento di tutti i tickets
   * @param queryString Quesry string contenente eventuali filtri, ordinamenti, ...
   */
  public getTickets(queryString?: string) : Promise<Ticket[]> {
    let url = environment.url + "/ticket/";

    if (queryString)
      url += "?q=" + queryString;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.localStorageService.getJwtToken()
      })
    };

    return this.httpClient.get<Ticket[]>(url, httpOptions).toPromise();
  }


  /**
   * Metodo per eseguire la chiamata PUT per l'aggiornamento dei dati di tickets
   * @param tickets I tickets da modificare
   */
  public updateTicket(tickets: any): Promise<any> {
    let url = environment.url + "/ticket/";

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.localStorageService.getJwtToken()
      })
    };

    return this.httpClient.put(url, tickets, httpOptions).toPromise();
  }

}
