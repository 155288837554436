import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Filter } from 'src/app/interfaces/filter';
import { HttpFilter } from 'src/app/interfaces/http-filter';
import { Priority } from 'src/app/interfaces/priority';
import { Status } from 'src/app/interfaces/status';
import { Type } from 'src/app/interfaces/type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @Output("filter") filterEvent = new EventEmitter<HttpFilter>();

  @Output("sort") sortEvent = new EventEmitter<any>();
  @Output("deleteSort") deleteSortEvent = new EventEmitter<string>();

  public priorities: Priority[];
  public statuses: Status[];
  public types: Type[];

  public filter: Filter = {
    type: "",
    creationDate: {},
    expirationDate: {},
    priority: "",
    state: ""
  };

  public createdAtDates = {
    from: "",
    to: ""
  };

  public expirationAtDates = {
    from: null,
    to: null
  };


  constructor() { 
    this.priorities = environment.priorities;
    this.statuses = environment.statuses;
    this.types = environment.types;
  }

  ngOnInit(): void {
  }


  /**
   * Ogni volta che viene modificato il valore di uno dei 4 campi di ricerca deve essere fatta la richiesta per prendere i nuovi dati filtrati
   */
  public fieldValueChanged() {

    let httpFilter = this.generateHttpFilterObject();

    this.filterEvent.emit(httpFilter);
  }


  /**
   * Metodo per la generazione dell'oggetto che verrà utilizzato per passare al server i dati per il filtraggio
   */
  private generateHttpFilterObject() {
    let httpFilter: HttpFilter = { created_at: {}, expiration_date: {} };

    if (this.createdAtDates.to)
      httpFilter.created_at.$lt = parseInt(Date.parse(this.createdAtDates.to).toString().substr(0, 10));
    else
      delete httpFilter.created_at.$lt;

    if (this.createdAtDates.from)
      httpFilter.created_at.$gt = parseInt(Date.parse(this.createdAtDates.from).toString().substr(0, 10));
    else
      delete httpFilter.created_at.$gt;

    if (Object.keys(httpFilter.created_at).length == 0) 
      delete httpFilter.created_at;

    if (this.expirationAtDates.to)
      httpFilter.expiration_date.$lt = parseInt(Date.parse(this.expirationAtDates.to).toString().substr(0, 10));
    else
      delete httpFilter.expiration_date.$lt;

    if (this.expirationAtDates.from)
      httpFilter.expiration_date.$gt = parseInt(Date.parse(this.expirationAtDates.from).toString().substr(0, 10));
    else
      delete httpFilter.expiration_date.$gt;

    if (Object.keys(httpFilter.expiration_date).length == 0) 
      delete httpFilter.expiration_date;

    if (this.filter.priority)
      httpFilter.priority = parseInt(this.filter.priority);

    if (this.filter.state)
      httpFilter.status = parseInt(this.filter.state);

    if (this.filter.type)
      httpFilter.request_type = this.filter.type;

    return httpFilter;
  }


  /**
   * Metodo per l'ordinamento che emette semplicemente l'evento
   * @param item Oggetto da emettere con i dati dell'ordinamento
   */
  public sort(item: any) {
    this.sortEvent.emit(item);
  }


  /**
   * Metodo per l'eliminazione dell'ordinamento che emette semplicemente l'evento
   * @param field Campo di cui eliminare l'ordinamento da emettere
   */
  public deleteSort(field: string) {
    this.deleteSortEvent.emit(field);
  }


}
