import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.css']
})
export class SortComponent implements OnInit {

  public icon: string = "import_export";
  @Input("name") fieldName: string;

  @Output("sort") sortEvent = new EventEmitter<any>();
  @Output("deleteSort") deleteSortEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }


  /**
   * Metodo che gestisce il click dell'ordinamento e va a modificare l'icona oltre al valore dell'ordinamento effettivo
   */
  public sort() {

    if (this.icon === "import_export") {
      this.icon = "arrow_drop_down";
      this.sortEvent.emit({ name: this.fieldName, value: -1 });
    } else if (this.icon === "arrow_drop_down") {
      this.icon = "arrow_drop_up";
      this.sortEvent.emit({ name: this.fieldName, value: 1 });
    } else {
      this.icon = "import_export";
      this.deleteSortEvent.emit(this.fieldName);
    }
 
  }

}
