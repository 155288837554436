<!--<div class="row mt-5">
    <div class="col-2 offset-5">
        <form [formGroup]="loginForm" (ngSubmit)="login()" class="form-signin text-center">
            


            <label for="inputEmail" class="sr-only">Email address</label>
            <input type="email" id="inputEmail" class="form-control" placeholder="Email" required="" autofocus="">

            <label for="inputPassword" class="sr-only">Password</label>
            <input type="password" id="inputPassword" class="form-control mt-2" placeholder="Password" required="">

            <button class="btn btn-primary btn-block mt-3" type="submit">Login</button>
        </form>
    </div>
</div>-->


<div class="row mt-5">
    <div class="col-2 offset-5 text-center">

        <h1 class="h3 font-weight-normal">Accedi a TNS</h1>

        <form [formGroup]="loginForm" (ngSubmit)="login()">
        
            <div class="row mt-3">
                <div class="input-group col-12">
                    <input type="text" class="form-control" style="width: 100%;" placeholder="Email" formControlName="email" [class.error-input]="hasError('email', 'required')">
                </div>
            </div>
            <div class="row">
                <div class="error-text col-12" *ngIf="hasError('email', 'required')">Email obbligatoria</div>
            </div>
        
        
            <div class="row mt-3">
                <div class="input-group col-12">
                    <input type="password" class="form-control" placeholder="Password" formControlName="password" [class.error-input]="hasError('password', 'required')">
                </div>
            </div>
            <div class="row"> 
                <div class="error-text" *ngIf="hasError('password', 'required')">Password obbligatoria</div>
            </div>
        
        
            <div class="row mt-3">
                <button type="submit" class="btn btn-primary col-10 offset-1">Login</button>                
            </div>
        
        </form>

    </div>
</div>