<div id="main" class="row">

    <div class="col-12">

        <!-- Labels -->
        <div class="row labels-row">
            <div class="col-2 item-2">
                <div>
                    <span class="label">Tipologia</span>
                    <sort [name]="'request_type'" (sort)="sort($event)" (deleteSort)="deleteSort($event)"></sort>
                </div>
            </div>
            <div class="col-2 item-2">
                <div>
                    <span class="label">Data creazione</span>
                    <sort [name]="'created_at'" (sort)="sort($event)" (deleteSort)="deleteSort($event)"></sort>
                </div>
            </div>
            <div class="col-2 item-2">
                <div>
                    <span class="label">Stato</span>
                    <sort [name]="'status'" (sort)="sort($event)" (deleteSort)="deleteSort($event)"></sort>
                </div>
            </div>
            <div class="offset-2 col-2 item-2">
                <div>
                    <span class="label">Data scadenza</span>
                    <sort [name]="'expiration_date'" (sort)="sort($event)" (deleteSort)="deleteSort($event)"></sort>
                </div>
            </div>
            <div class="col-2 item-2">
                <div>
                    <span class="label">Priorità</span>
                    <sort [name]="'priority'" (sort)="sort($event)" (deleteSort)="deleteSort($event)"></sort>
                </div>
            </div>
        </div>

    
        <!-- Contents -->
        <div class="row contents-row">
            <div class="col-2 item-2">
                <div>
                    <select class="input-box-2" [(ngModel)]="filter.type" (change)="fieldValueChanged()">
                        <option value=""></option>
                        <option *ngFor="let type of types" value="{{ type.value }}">{{ type.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-2 item-2 datepicker-column">
                <div>
                    <mat-form-field class="datepicker-field" appearance="outline" floatLabel="never">
                        <mat-date-range-input [rangePicker]="createdAt" (focusout)="fieldValueChanged()">
                          <input matStartDate [(ngModel)]="createdAtDates.from" placeholder="Dal">
                          <input matEndDate [(ngModel)]="createdAtDates.to" placeholder="Al">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="createdAt"></mat-datepicker-toggle>
                        <mat-date-range-picker #createdAt (closed)="fieldValueChanged()"></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-2 item-2">
                <div>
                    <select class="input-box-2"[(ngModel)]="filter.state" (change)="fieldValueChanged()">
                        <option value=""></option>
                        <option *ngFor="let status of statuses" value="{{ status.value }}">{{ status.name }}</option>
                    </select>
                </div>
            </div>
            <div class="offset-2 col-2 item-2 datepicker-column">
                <div>
                    <mat-form-field class="datepicker-field" appearance="outline" floatLabel="never">
                        <mat-date-range-input [rangePicker]="expirationAt" (focusout)="fieldValueChanged()">
                          <input matStartDate [(ngModel)]="expirationAtDates.from" placeholder="Dal">
                          <input matEndDate [(ngModel)]="expirationAtDates.to" placeholder="Al">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="expirationAt"></mat-datepicker-toggle>
                        <mat-date-range-picker #expirationAt (closed)="fieldValueChanged()"></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-2 item-2">
                <div>
                    <select class="input-box-2" [(ngModel)]="filter.priority" (change)="fieldValueChanged()">
                        <option value=""></option>
                        <option *ngFor="let priority of priorities" value="{{ priority.value }}">{{ priority.name }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

</div>