<div id="main" [style.border-color]="borderColor">

    <div id="sub-main" [style.border-right-color]="priorityColor">

        <div class="row col-12">

            <div class="col-6 item info">
                <div class="item-title">#{{ticket._id}} - {{ticket.subject || "Unknown"}}</div>
                <div class="item-info">
                    <div class="item-info-row">
                        <div class="item-info-item item-info-data"><mat-icon class="info-icon" aria-label="Data">calendar_today</mat-icon><span>{{ (ticket.created_at | date:"dd/MM/yyyy") || "Unknown" }}</span><span style="padding-left: 5px; padding-right: 5px;">-</span><input type="date" [(ngModel)]="expirationDate" class="input-box" (focusout)="expirationDateChanged()" [disabled]="!enabledInputField"></div>
                        <div class="item-info-item item-info-changetype"><mat-icon class="info-icon" aria-label="Ore ticket">hourglass_top</mat-icon><input type="number" class="input-box" [(ngModel)]="ticket.estimated_time" (focusout)="estimatedTimeChanged()" [disabled]="!enabledInputField"></div>
                    </div>
                    <div class="item-info-row">
                        <div class="item-info-item item-info-name"><mat-icon class="info-icon" aria-label="Utente">sentiment_very_satisfied</mat-icon><span>{{ticket.requester[0].name || "Unknown" }}</span></div>
                        <div class="item-info-item item-info-priority">
                            <mat-icon class="info-icon" aria-label="Priorità">priority_high</mat-icon>
                            <select [(ngModel)]="ticket.priority" class="input-box" (change)="priorityChanged()" [disabled]="!enabledInputField">
                                <option *ngFor="let priority of priorities" value="{{ priority.value }}">{{ priority.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-5 item content-2">{{ticket.text || "Unknown" }}
            </div>

            <div class="col-1 item links">
                <div class="item-link">
                    <input type="image" class="gitea-button" src="assets/img/gitea-logo.png" (click)="navigate(ticket.issue_id, 'gitea')" />
                </div> 
                <div class="item-link">
                    <input type="image" class="freshdesk-button" src="assets/img/freshdesk-logo.png" (click)="navigate(ticket._id, 'freshdesk')" />
                </div>
            </div>

        </div>

    </div>

</div>