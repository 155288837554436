<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
        <span class="ml-auto navbar-text" style="color: black;">Ciao, {{ localStorageService.getUserName() }}</span>
        <span class="ml-5 mr-3" style="cursor: pointer;" (click)="logout()">Logout</span>
    </div>
</nav>

<br>

<div class="container-fluid pb-5">
    <div class="row">
        <div class="offset-1 col-10">
            <search (filter)="filter($event)"  (sort)="sort($event)" (deleteSort)="deleteSort($event)"></search>
        </div>
    </div>
    <div class="row mt-5">
        <div class="offset-5 col-2">
            <paginator [limits]="[{ text: '5', value: 5 }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: '50', value: 50 }, { text: 'Tutti', value: null }]" (limit)="limit($event)"></paginator>
        </div>
    </div>
    <div class="row ticket-section mt-3">
        <div class="offset-1 col-10">
            <div class="ticket mt-4" *ngFor="let ticket of tickets">
                <ticket [ticket]="ticket"></ticket>
            </div>
        </div>
    </div>
</div>