import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextValidatorService {

  private charWeights: any[] = [
    { char: "il.,:;-!?'\" ", weight: 1 }, // se la lettera è una di queste il peso della singola lettera è 1
    { char: "QWERTYUOPLKJHGFDSAZXCVBNM234567890", weight: 3 }, // se la lettera è una di queste il peso della singola lettera è 3
  ]; // Altrimenti è 2


  constructor() { }


   /**
    * Metodo che a partire da una stringa e da un peso massimo ritorna la suddetta stringa eventualmente troncata
    * @param value Stringa da elaborare
    * @param maxWeight Peso massimo supportato
    */
  public getStringWithMaxWeight(value: string, maxWeight: number) : string {

    let charsArray = value.split("");
    let weight = 0;
    let lastValidString = "";

    for (let char of charsArray) {
      let charWeight = this.charWeights.filter(c => c.char.includes(char));

      if (charWeight && charWeight.length > 0)
        weight += charWeight[0].weight;
      else
        weight += 2;

      if (weight > maxWeight)
        return lastValidString + "...";

      lastValidString += char;
    }

    return lastValidString;
  }
  
}
