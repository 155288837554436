import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginReq } from '../interfaces/loginReq';
import { LoginRes } from '../interfaces/loginRes';
import { AuthService } from '../services/http/auth/auth.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup = null;


  constructor(private formBuilder: FormBuilder, private localStorageService: LocalStorageService, private router: Router, private authHttpService: AuthService) {}


  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required])
    });
  }


  public async login() {
    this.loginForm.markAllAsTouched();

    let formControllers = Object.keys(this.loginForm.controls);

    let errors: boolean = false;

    formControllers.forEach(controller => {
      if (this.loginForm.controls[controller].errors != null) // Se ci sono errori in almeno un campo
        errors = true;
    });

    if (errors)
      return;

    let loginReq: LoginReq = {
      email: this.loginForm.controls["email"].value,
      password: this.loginForm.controls["password"].value
    };

    try {
      let response: LoginRes = await this.authHttpService.login(loginReq);
      this.localStorageService.setJwtToken(response.token);
      this.router.navigate(['home']);
    } catch(ex) {
      alert(ex.error.message);
    }
    
  }


  public hasError(field: string, error: string) {
    return this.loginForm.get(field).hasError(error) && this.loginForm.get(field).touched;
  }
}