import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Limit } from 'src/app/interfaces/limit';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @Input("limits") limits: Limit[];
  @Output("limit") limitEvent = new EventEmitter<number>();
  
  public selectedLimit: string = "";


  constructor() { }

  /**
   * Metodo che provvede ad impostare il limite selezionato di default
   */
  ngOnInit(): void {
    if (this.limits)
      this.selectedLimit = this.limits[0].text;
  }


  /**
   * Metodo per la modifica del limite selezionato che emette l'evento verso il componente padre per poter eseguire la nuova richiesta HTTP
   * @param newLimit Il nuovo limite selezionato
   */
  public changeLimit(newLimit: Limit) {

    if (newLimit.text === this.selectedLimit)
      return;

    this.selectedLimit = newLimit.text;
    this.limitEvent.emit(newLimit.value);
  }

}
