import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private snackbarService: MatSnackBar;


  constructor(snackBar: MatSnackBar) { 
    this.snackbarService = snackBar;
  }


  /**
   * Metodo per la creazione dello snackbar
   * @param message Messaggio da visualizzare nello snackbar
   * @param buttonText Testo del pulsante
   * @param duration Durata di visualizzazione
   * @param hposition Posizione orizzontale
   * @param vposition Posizione verticale
   */
  public create(message: string, buttonText: string = "OK!", duration: number = 5000, hposition: MatSnackBarHorizontalPosition = "start", vposition: MatSnackBarVerticalPosition = "bottom") {
    this.snackbarService.open(message, buttonText, {
      duration: duration,
      horizontalPosition: hposition,
      verticalPosition: vposition
    });
  }
}
