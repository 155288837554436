import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginReq } from 'src/app/interfaces/loginReq';
import { LoginRes } from 'src/app/interfaces/loginRes';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) { }


  public login(body: LoginReq): Promise<LoginRes> {
    let url: string = environment.url + "/user/login";
    return this.httpClient.post<LoginRes>(url, body).toPromise();
  }


  public logout(): Promise<any> {
    let url: string = environment.url + "/user/logout";

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.localStorageService.getJwtToken()
      })
    };

    return this.httpClient.post(url, {}, httpOptions).toPromise();
  }
}
