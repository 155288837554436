import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }



  public setJwtToken(token: string): void {
    localStorage.setItem("jwt_tns", token);
  } 


  public getJwtToken(): string {
    return localStorage.getItem("jwt_tns");
  }


  public isThereJwtToken(): boolean {
    return localStorage.getItem("jwt_tns") != null;
  }


  public getUserName() {
    let jwt: string = localStorage.getItem("jwt_tns");

    let decodedJwt: object = jwtDecode(jwt);

    let name: string = decodedJwt["name"];
    let lastname: string = decodedJwt["lastname"];

    return name + " " + lastname;
  }


  public removeJwtToken() {
    localStorage.removeItem("jwt_tns");
  }

}
