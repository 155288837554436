import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Priority } from 'src/app/interfaces/priority';
import { Ticket } from 'src/app/interfaces/ticket';
import { Type } from 'src/app/interfaces/type';
import { HttpTicketService } from 'src/app/services/http/ticket/http-ticket.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { TextValidatorService } from 'src/app/services/text/text-validator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {

  @Input("ticket") ticket: Ticket;
  @Output("updatedTicket") updatedTicket = new EventEmitter<Ticket>();
  @Output("deleteUpdate") deleteUpdate = new EventEmitter<number>();

  private httpTicketService: HttpTicketService;
  private snackbarService: SnackbarService;
  public enabledInputField: boolean = true;
  public borderColor: string = "rgb(238, 238, 238)";

  private updatedTimeout = null;

  public priorities: Priority[];

  private textValidatorService: TextValidatorService = null;
  public priorityColor: string = "";
  public isTicketUpdated: boolean = false;

  private originTicketValue: Ticket; // Mantengo il valore del ticket originario per verificare eventuali modifiche

  public types: Type[];

  public expirationDate: string = null;

  private freshdeskUrl = "";
  private giteaUrl = "";


  constructor(textValidatorService: TextValidatorService, httpTicketService: HttpTicketService, snackbarService: SnackbarService) {
    this.textValidatorService = textValidatorService;
    this.priorities = environment.priorities;
    this.httpTicketService = httpTicketService;
    this.snackbarService = snackbarService;
    this.types = environment.types;
    this.freshdeskUrl = environment.freshdeskUrl;
    this.giteaUrl = environment.giteaUrl;
  }


  /**
   * Metodo utile per sanitizzare i campi del ticket ricevuto dal server ed impostare il colore del bordo destro in base alla priorità
   */
  ngOnInit(): void {

    this.restoreObject();

    this.originTicketValue = JSON.parse(JSON.stringify(this.ticket));

    this.ticket.subject = this.textValidatorService.getStringWithMaxWeight(this.ticket.subject ?? "", 65);
    this.ticket.requester[0].name = this.textValidatorService.getStringWithMaxWeight(this.ticket.requester[0].name ?? "", 45);
    this.ticket.text = this.textValidatorService.getStringWithMaxWeight(this.ticket.text ?? "", 425);

    let color = this.types.filter(type => type.name == this.ticket.request_type).map(type => type.color);

    if (color && color.length > 0)
      this.priorityColor = color[0];
    else
      this.priorityColor = "white";

  }


  /**
   * Metodo per sanitizzare i vari campi del ticket
   */
  private restoreObject() {

    if (!this.ticket.requester || this.ticket.requester.length == 0)
      this.ticket.requester.push({ _id: 0, name: "Unknown" });

    if (typeof(this.ticket.priority) == typeof(""))
      this.ticket.priority = parseInt(this.ticket.priority.toString());

    if (this.ticket.created_at.toString().length == 10)
      this.ticket.created_at = parseInt(this.ticket.created_at.toString() + "000");

    if (this.ticket.expiration_date && this.ticket.expiration_date.toString().length == 10)
      this.ticket.expiration_date = parseInt(this.ticket.expiration_date.toString() + "000");

    if (this.ticket.expiration_date != null) {
      this.expirationDate = new Date(this.ticket.expiration_date).toISOString();
      this.expirationDate = this.expirationDate.substr(0, this.expirationDate.indexOf("T"));
    }

  }


  /**
   * Navigazione verso la piattaforma di Gitea e Freshdesk
   * @param value Valore che identifica il ticket o la issue
   * @param platform Piattaforma verso la quale navigare
   */
  public navigate(value: any, platform: string) {

    if (platform == "freshdesk")
      window.open(this.freshdeskUrl + value, "_blank");
    else 
      window.open(this.giteaUrl + value, "_blank");

  }


  /**
   * Metodo richiamato per l'aggiornamento delle ore stimate nel momento in cui viene modificato il suo valore
   */
  public async estimatedTimeChanged() {

    if (this.ticket.estimated_time == this.originTicketValue.estimated_time)
      return;

    let result = await this.updateRequest({ _id: this.ticket._id, estimated_time: this.ticket.estimated_time });

    if (result)
      this.originTicketValue.estimated_time = this.ticket.estimated_time;
  }


  /**
   * Metodo richiamato per l'aggiornamento della data di scadenza nel momento in cui viene modificato il suo valore
   */
  public async expirationDateChanged() {

    let expirationDate = Date.parse(this.expirationDate);

    if (!expirationDate) {
      this.snackbarService.create("Data non valida");
      this.expirationDate = null;
      return;
    }

    if (expirationDate == this.originTicketValue.expiration_date)
      return;

    console.log(Date.parse(this.expirationDate));
    console.log(parseInt(Date.parse(this.expirationDate).toString().substr(0, 10)));

    let result = await this.updateRequest({ _id: this.ticket._id, expiration_date: parseInt(Date.parse(this.expirationDate).toString().substr(0, 10)) });

    if (result) {
      this.ticket.expiration_date = Date.parse(this.expirationDate);
      this.originTicketValue.expiration_date = Date.parse(this.expirationDate);
    }
  }


  /**
   * Metodo richiamato per l'aggiornamento della priorità nel momento in cui viene modificato il suo valore
   */
  public async priorityChanged() {

    if (this.ticket.priority == this.originTicketValue.priority)
      return;

    let result = await this.updateRequest({ _id: this.ticket._id, priority: parseInt(this.ticket.priority.toString()) });

    if (result)
      this.originTicketValue.priority = this.ticket.priority;
  }


  /**
   * Metodo per eseguire l'effettivo aggiornamento
   * @param obj Oggetto da inviare al server per la modifica
   * 
   */
  private async updateRequest(obj: any) {

    this.enabledInputField = false;

    try {
      await this.httpTicketService.updateTicket([ obj ]);
      this.successUpdated();
      return true;
    } catch(e) {
      this.unsuccessUpdated(e);
      return false;
    }
  }


  /**
   * Metodo richiamato nel momento in cui la chiamata HTTP ha esito positivo
   */
  private successUpdated() {
    this.enabledInputField = true;
    this.borderColor = "green";
    this.updatedTimeout = setTimeout(() => this.borderColor = "rgb(238, 238, 238)", 2500);
    this.snackbarService.create("Dati del ticket '" + this.ticket._id + "' modificati con successo!", "OK!");
  }


  /**
   * Metodo richiamato se la chiamata HTTP ha esito negativo
   * @param e Eccezione sollevata
   */
  private unsuccessUpdated(e: any) {
    this.enabledInputField = true;
    this.borderColor = "red";
    this.updatedTimeout = setTimeout(() => this.borderColor = "rgb(238, 238, 238)", 2500);
    this.snackbarService.create("Si è verificato un errore durante l'aggiornamento dei dati del ticket '" + this.ticket._id + "'. Per ulteriori informazioni consultare la console.", "OK!");
    console.error(e);
  }

}
